// TEASER
@import "core";

@include respond-to(base-inline) {
  // Teaser
  :where(.tc_teaser){position:relative;min-width:0;} // 'min-width:0' to handle long words AND focus outline
  :where(.tc_teaser__link){display:flex;flex-direction:column;position:relative;text-decoration:none;color:inherit;column-gap:Min(2.4%, var(--spacing-8-step));}
  :where(.tc_teaser__label){display:flex;max-width:77%;
    .tc_label{max-width:none;
      &:first-child:not(:only-child){flex:0 0 auto;}
    }
  }
  :where(.tc_teaser__header){display:flex;flex-direction:column;gap:var(--spacing-8);flex:1 1 auto;position:relative;line-height:0;min-width:0;}
  :where(.tc_teaser__tagline){display:flex;align-items:center;position:relative;font-size:var(--font-12);line-height:var(--line-140);color:inherit;
    .tc_teaser__tagline__text{opacity:.7;}
  }
  :where([data-teaser-media-type]:not([data-teaser-media-type="play"]) .tc_teaser__tagline){
    &::before,&::after{content:"";display:block;min-width:var(--spacing-32);min-height:var(--spacing-32);}
    &::before{border-radius:50%;background-color:var(--color-dark-200);margin-right:var(--spacing-4);}
    &::after{position:absolute;left:0;top:0;mask-repeat:no-repeat;mask-position:50% 50%;mask-size:var(--spacing-16) auto;}
  }

  // powerlink
  .tc_teaser--powerlink{margin:0 var(--spacing-8-jump);
    // reel
    &[data-teaser-media-type="reel"]{
      .tc_video{flex:0 0 auto;}
      .tc_video__video{min-width:var(--spacing-80);max-width:var(--spacing-80);aspect-ratio:3/4;object-fit:cover;
        & > .tc_video__videoloop{background-size:var(--spacing-16-step) auto;}
      }
    }
  }

  // related
  :where(.tc_teaser--related){max-width:calc(784 * var(--static-unit));
    // no image
    &:not(:has(.tc_image)){
      .tc_teaser__header{margin-left:var(--spacing-8-step);
        &::before{content:"";position:absolute;top:0;bottom:.2ex;left:calc(var(--spacing-8-step) * -1);border-left:var(--spacing-4) solid var(--color-dark-200);}
      }
    }
  }

  // live videostream
  :where(.tc_teaser--livestream){
    .vjs-control-bar{padding-bottom:var(--spacing-8-step);}
    .vjs-subs-caps-button{display:none;}
  }

  // videoloop
  :where(.tc_teaser--video-teaser){
    .tc_video__video{overflow:hidden;}
    .tc_video__videoloop{position:relative;pointer-events:none;margin:0 auto;transform:scale(1.01);} // scale videoloop slightly because perfect aspect ratio isn't possible
  }

  // reel
  [data-teaser-media-type="reel"]{
    .tc_image__picture,.tc_video__video{aspect-ratio:9/16;}
    .tc_video__video{position:relative;background-color:var(--color-neutral-800);}
  }

  // image top - if image is present but label is missing
  :where([data-teaser-layout-bs="top"]:has(.tc_image,.tc_video):not(:has(.tc_label))){
    :where(.tc_teaser__header){padding-top:var(--spacing-8);}
  }

  // image left & right
  :where([data-teaser-layout-bs="left"],[data-teaser-layout-bs="right"]){
    [sizes*="22.225vw"] + .tc_image__picture__img{min-width:var(--spacing-80);}
    [sizes*="40vw"] + .tc_image__picture__img{min-width:calc(144px * var(--unit));}
    .tc_image{flex:0 0 auto;}
  }
  :where([data-teaser-layout-bs="left"]) .tc_teaser__link{flex-direction:row;}
  :where([data-teaser-layout-bs="right"]) .tc_teaser__link{flex-direction:row-reverse;}

  // teaser with background (currently 'breaking' only)
  :where([class*=tc_teaser--bg]:not(.tc_deck--breaking [class*=tc_teaser--bg]):not([class*="tc_grid--bg-breaking"] [class*=tc_teaser--bg])){
    .tc_teaser__header{padding:var(--spacing-8);}
    &[data-teaser-layout-bs="top"] .tc_teaser__header{padding-top:0;
      .tc_teaser__label{margin-left:calc(var(--spacing-8) * -1);}
    }
    &[data-teaser-layout-bs="left"] .tc_teaser__header{padding-left:0;}
    &[data-teaser-layout-bs="right"] .tc_teaser__header{padding-right:0;}
  }
  .tc_teaser--bg-breaking{background-color:var(--color-breaking);color:var(--color-midnight);}
}

@include respond-to(base-to-medium-inline) {
  // font-size tweaks
  .tc_teaser--related{
    .tc_heading{font-size:var(--font-16);}
  }

  [data-prio-layout="side-by-side"]{
    .tc_heading--2{font-size:var(--font-20);}
    .tc_heading--3{font-size:var(--font-18);}
    .tc_heading--4{font-size:var(--font-16);}
  }

  [data-prio-layout="columns"]{
    .tc_grid .tc_grid{
      .tc_heading--3{font-size:var(--font-20);}
      .tc_heading--4{font-size:var(--font-18);}
      .tc_heading--5{font-size:var(--font-16);}
    }
  }

  [data-teaser-layout-bs="left"],[data-teaser-layout-bs="right"]{
    .tc_heading--1{font-size:var(--font-20);}
    .tc_heading--2{font-size:var(--font-18);}
    .tc_heading--3,.tc_heading--4,.tc_heading--5{font-size:var(--font-16);}
  }
}

@include respond-to(base) {
  // powerlink (reel)
  .tc_teaser--powerlink[data-teaser-media-type="reel"] .tc_teaser__tagline{
    &::before{background-color:var(--color-dark);}
    &::after{background-color:var(--color-light);}
  }

  // media types
  [data-teaser-media-type="blog"] .tc_teaser__tagline::after{mask-image:url("inline!../../../../icons/quotation.svg");}
  [data-teaser-media-type="video"] .tc_teaser__tagline::after{mask-image:url("inline!../../../../icons/play.svg");}
  [data-teaser-media-type="gallery"] .tc_teaser__tagline::after{mask-image:url("inline!../../../../icons/camera.svg");}
  [data-teaser-media-type="reel"] .tc_teaser__tagline::after{mask-image:url("inline!../../../../icons/play.svg");}

  :where([data-teaser-media-type] .tc_teaser__tagline)::after{background-color:var(--color-dark);}
  :where(.tc_grid--bg-dark,.tc_deck--bg-900,.tc_deck--bg-midnight,.tc_deck--bg-tv2red,.tc_deck--bg-blue,.tc_deck--bg-green,.tc_deck--bg-violet,.tc_deck--bg-dark-bordeaux,.tc_deck--bg-dark-nightblue,.tc_deck--bg-dark-forestgreen,.tc_deck--bg-live){
    [data-teaser-media-type]:not(tc_teaser--bg-breaking) .tc_teaser__tagline{
      &::before{background-color:var(--color-white-200);}
      &::after{background-color:var(--color-white);}
    }
  }

  // station logos
  [data-teaser-station] .tc_image::before{content:"";position:absolute;z-index:2;left:var(--spacing-8);top:var(--spacing-8);width:80%;height:var(--spacing-24);background-repeat:no-repeat;background-size:contain;}
  [data-teaser-station="TV2"] .tc_image::before{background-image:url("../../../../gfx/logos/svg/tv2.svg");}
  [data-teaser-station="TV2NEWS"] .tc_image::before{background-image:url("../../../../gfx/logos/svg/tv2-news_white.svg");}
  [data-teaser-station="TV2FRI"] .tc_image::before{background-image:url("../../../../gfx/logos/svg/tv2-fri_white.svg");}
  [data-teaser-station="TV2CHARLIE"] .tc_image::before{background-image:url("../../../../gfx/logos/svg/tv2-charlie.svg");}
  [data-teaser-station="TV2SPORT"] .tc_image::before{background-image:url("../../../../gfx/logos/svg/tv2-sport_white.svg");}
  [data-teaser-station="TV2SPORTX"] .tc_image::before{background-image:url("../../../../gfx/logos/svg/tv2-sportx_white.svg");}
  [data-teaser-station="TV2ECHO"] .tc_image::before{background-image:url("../../../../gfx/logos/svg/tv2-echo_white.svg");}
  [data-teaser-station="BRITBOX"] .tc_image::before{background-image:url("../../../../gfx/logos/svg/partners/britbox_white.svg");}
  [data-teaser-station="CMORE"] .tc_image::before{background-image:url("../../../../gfx/logos/svg/partners/c-more_white.svg");}
  [data-teaser-station="OIII"] .tc_image::before{background-image:url("../../../../gfx/logos/svg/partners/oiii_white.svg");}
  [data-teaser-station="SFKIDS"] .tc_image::before{background-image:url("../../../../gfx/logos/svg/partners/sf-kids_white.svg");}
  [data-teaser-station="SKYSHOWTIME"] .tc_image::before{background-image:url("../../../../gfx/logos/svg/partners/skyshowtime_white.svg");}
}

@include respond-to(medium-inline) {
  // image top
  [data-teaser-layout-md="top"]{
    &[data-teaser-layout-bs="left"],&[data-teaser-layout-bs="right"]{
      [sizes] + .tc_image__picture__img{min-width:0;}
    }
    .tc_teaser__link{flex-direction:column;}
    .tc_teaser__header:nth-child(2) .tc_heading:first-child{padding-top:var(--spacing-8);}
  }

  // image left & right
  [data-teaser-layout-bs="left"],[data-teaser-layout-bs="right"],
  [data-teaser-layout-md="left"],[data-teaser-layout-md="right"]{
    .tc_teaser__label{margin-left:0;}
    [sizes*="144px"] + .tc_image__picture__img{min-width:calc(144 * var(--unit));}
    [sizes*="198px"] + .tc_image__picture__img{min-width:calc(198 * var(--unit));}
    [sizes*="304px"] + .tc_image__picture__img{min-width:calc(304 * var(--unit));}
  }
  [data-teaser-layout-md="left"],[data-teaser-layout-md="right"]{
    .tc_teaser__header:nth-child(2) .tc_heading:first-child{padding-top:0;}
  }
  [data-teaser-layout-md="left"] .tc_teaser__link{flex-direction:row;}
  [data-teaser-layout-md="right"] .tc_teaser__link{flex-direction:row-reverse;}

  // teaser with background (currently 'breaking' only)
  :where([class*=tc_teaser--bg]:not(.tc_deck--breaking [class*=tc_teaser--bg]):not([class*="tc_grid--bg-breaking"] [class*=tc_teaser--bg])){
    &[data-teaser-layout-md="top"] .tc_teaser__header{padding:0 var(--spacing-8) var(--spacing-8) var(--spacing-8);
      .tc_teaser__label{margin-left:calc(var(--spacing-8) * -1);}
    }
    &[data-teaser-layout-md="left"] .tc_teaser__header{padding:var(--spacing-8) var(--spacing-8) var(--spacing-8) 0;
      .tc_teaser__label{margin-left:0;}
    }
    &[data-teaser-layout-md="right"] .tc_teaser__header{padding:var(--spacing-8) 0 var(--spacing-8) var(--spacing-8);
      .tc_teaser__label{margin-left:0;}
    }
  }
}

@include respond-to(large-inline) {
  // image top
  [data-teaser-layout-lg="top"]{
    &[data-teaser-layout-bs="left"],&[data-teaser-layout-bs="right"],
    &[data-teaser-layout-md="left"],&[data-teaser-layout-md="right"] {
      [sizes] + .tc_image__picture__img{min-width:0;}
    }
    .tc_teaser__link{flex-direction:column;}
    .tc_teaser__header:nth-child(2) .tc_heading:first-child{padding-top:var(--spacing-8);}
  }

  // image left & right
  [data-teaser-layout-bs="left"],[data-teaser-layout-bs="right"],
  [data-teaser-layout-md="left"],[data-teaser-layout-md="right"],
  [data-teaser-layout-lg="left"],[data-teaser-layout-lg="right"]{
    [sizes*="224px"] + .tc_image__picture__img{min-width:calc(224 * var(--unit));}
    [sizes*="544px"] + .tc_image__picture__img{min-width:calc(544 * var(--unit));}
  }
  [data-teaser-layout-lg="left"],[data-teaser-layout-lg="right"]{
    .tc_teaser__header:nth-child(2) .tc_heading:first-child{padding-top:0;}
  }
  [data-teaser-layout-lg="left"] .tc_teaser__link{flex-direction:row;}
  [data-teaser-layout-lg="right"] .tc_teaser__link{flex-direction:row-reverse;}

  // teaser with background (currently 'breaking' only)
  :where([class*=tc_teaser--bg]:not(.tc_deck--breaking [class*=tc_teaser--bg]):not([class*="tc_grid--bg-breaking"] [class*=tc_teaser--bg])){
    &[data-teaser-layout-lg="top"] .tc_teaser__header{padding:0 var(--spacing-8) var(--spacing-8) var(--spacing-8);
      .tc_teaser__label{margin-left:calc(var(--spacing-8) * -1);}
    }
    &[data-teaser-layout-lg="left"] .tc_teaser__header{padding:var(--spacing-8) var(--spacing-8) var(--spacing-8) 0;
      .tc_teaser__label{margin-left:0;}
    }
    &[data-teaser-layout-lg="right"] .tc_teaser__header{padding:var(--spacing-8) 0 var(--spacing-8) var(--spacing-8);
      .tc_teaser__label{margin-left:0;}
    }
  }
}

@include respond-to(hover) {
  .tc_teaser__link[href]{
    .tc_heading{transition:opacity var(--transition-faster) ease-out;}
    &:hover{
      .tc_heading{opacity:var(--link-opacity-hover);}
      .tc_video__videoloop{cursor:pointer;}
    }
  }
}

@include respond-to(print) {
  .tc_teaser{width:100%;margin:0 0 var(--spacing-64);}
  .tc_teaser--related{margin-top:calc(var(--spacing-32) * -1);}
  .tc_teaser--powerlink{display:none;}
  .tc_teaser__link{color:inherit;text-decoration:none;}
  [data-prio-layout="side-by-side"]{display:flex;gap:var(--spacing-16);}
  [data-teaser-layout-bs="left"] .tc_teaser__link{display:flex;gap:var(--spacing-16);
    .tc_image{flex:1 0 auto;}
  }
  .tc_teaser__tagline{font-size:var(--font-14);}
}
